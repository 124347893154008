import { Redirect } from "react-router-dom";
import { lazy } from "react";
import { UserRole } from "helpers";

const OidcCallback = lazy(() => import("components/oidc/OidcCallback"));
const OidcSilentCallback = lazy(
  () => import("components/oidc/OidcSilentCallback")
);
const NotAuthenticated = lazy(
  () => import("components/oidc/NotAuthentication")
);
const NewDashboard = lazy(() => import("pages/new-dashboard/NewDashboard"));
const Report = lazy(() => import("pages/report/Report"));
const UserAccounts = lazy(() => import("pages/user-accounts/UserAccounts"));
const FarmerAccounts = lazy(
  () => import("pages/farmer-accounts/FarmerAccounts")
);
const DocumentHub = lazy(() => import("pages/document-hub/DocumentHub"));
const AlertManagement = lazy(
  () => import("pages/alert-management/AlertManagement")
);
const MySitesProducers = lazy(
  () => import("pages/my-sites-producers/MySitesProducers")
);
const NoRole = lazy(() => import("pages/auth/NoRole"));
const NotFound404 = lazy(() => import("pages/error/NotFound404"));
const ReportDetail = lazy(() => import("pages/report/ReportDetail"));
const WebConfig = lazy(() => import("pages/web-config/WebConfig"));
const Maintenance = lazy(() => import("pages/maintenance/Maintenance"));
const PGAForm = lazy(() => import("pages/PGAForm/PGAForm"));
const PGAFormReview = lazy(() => import("pages/pga-form-review/PGAFormReview"));
const PGAFormEditComponent = lazy(() => import("pages/pga-form-edit/PGAFormEditComponent"));
const CarcaseInfoEmail = lazy(
  () => import("pages/carcase-info-email/CarcaseInfoEmail")
);
const FarmGroups = lazy(
  () => import("pages/farm-groups/FarmGroups")
);

const routes: Array<{
  path: string;
  name?: string;
  component: any;
  title?: string;
  exact?: boolean;
  useAuthLayout?: boolean;
  permissions?: string[];
}> = [
  {
    path: "/auth/callback",
    component: OidcCallback,
    title: "Dashboard",
  },
  {
    path: "/auth/silent_callback",
    component: OidcSilentCallback,
    title: "Dashboard",
  },
  {
    path: "/auth/401",
    component: NotAuthenticated,
    title: "Dashboard",
  },
  {
    path: "/dashboard",
    name: "dashboard",
    component: NewDashboard,
    title: "Dashboard",
    useAuthLayout: true,
    permissions: [UserRole.Admin],
  },
  {
    path: "/report/detail",
    name: "report-detail",
    component: ReportDetail,
    title: "ReportDetail",
    useAuthLayout: true,
    permissions: [UserRole.Admin, UserRole.AgricultureManager],
  },
  {
    path: "/report",
    name: "report",
    component: Report,
    title: "Report",
    useAuthLayout: true,
    permissions: [UserRole.Admin, UserRole.AgricultureManager],
  },
  {
    path: "/user-accounts",
    name: "user-accounts",
    component: UserAccounts,
    title: "UserAccounts",
    useAuthLayout: true,
    permissions: [UserRole.Admin],
  },
  {
    path: "/farmer-accounts",
    name: "farmer-accounts",
    component: FarmerAccounts,
    title: "FarmerAccounts",
    useAuthLayout: true,
    permissions: [UserRole.Admin],
  },
  {
    path: "/document-hub",
    name: "document-hub",
    component: DocumentHub,
    title: "DocumentHub",
    useAuthLayout: true,
    permissions: [UserRole.Admin, UserRole.AgricultureManager, UserRole.Buyer],
  },
  {
    path: "/alert-management",
    name: "alert-management",
    component: AlertManagement,
    title: "AlertManagement",
    useAuthLayout: true,
    permissions: [UserRole.Admin, UserRole.AgricultureManager],
  },
  {
    path: "/my-sites-producers",
    name: "my-sites-producers",
    component: MySitesProducers,
    title: "MySitesProducers",
    useAuthLayout: true,
    permissions: [UserRole.Buyer, UserRole.AgricultureManager, UserRole.Admin],
  },
  {
    path: "/no-role",
    component: NoRole,
    title: "NoRole",
    useAuthLayout: false,
    permissions: [UserRole.NoRole],
  },
  {
    path: "/404",
    component: NotFound404,
    title: "NotFound404",
    useAuthLayout: false,
  },
  {
    path: "/web-config",
    name: "WebConfig",
    component: WebConfig,
    title: "WebConfig",
    useAuthLayout: true,
    permissions: [UserRole.Admin],
  },
  {
    path: "/pga-form/review",
    name: "pga-form-review",
    component: PGAFormReview,
    title: "PGAFormReview",
    useAuthLayout: true,
    permissions: [UserRole.Admin, UserRole.Buyer],
  },
  {
    path: "/pga-form/edit",
    name: "pga-form-edit",
    component: PGAFormEditComponent,
    title: "PGAFormEdit",
    useAuthLayout: true,
    permissions: [UserRole.Admin, UserRole.Buyer],
  },
  {
    path: "/pga-form",
    name: "PGAForm",
    component: PGAForm,
    title: "PGAForm",
    useAuthLayout: true,
    permissions: [UserRole.Admin, UserRole.Buyer],
  },
  {
    path: "/carcase-info-email",
    name: "carcaseInfoEmail",
    component: CarcaseInfoEmail,
    title: "CarcaseInfoEmail",
    useAuthLayout: true,
    permissions: [UserRole.Admin, UserRole.Buyer],
  },
  {
    path: "/farm-groups",
    name: "farmGroups",
    component: FarmGroups,
    title: "FarmGroups",
    useAuthLayout: true,
    permissions: [UserRole.Admin, UserRole.AgricultureManager],
  },
  {
    path: "/",
    exact: true,
    component: () => <Redirect to="/dashboard" />,
  },
  {
    path: "*",
    name: "notfound404",
    component: NotFound404,
    title: "NotFound404",
    useAuthLayout: false,
  },
];

const maintenanceRoutes: Array<{
  path: string;
  name?: string;
  component: any;
  title?: string;
  exact?: boolean;
  useAuthLayout?: boolean;
  permissions?: string[];
}> = [
  {
    path: "/web-config",
    name: "WebConfig",
    component: WebConfig,
    title: "WebConfig",
    useAuthLayout: true,
    permissions: [UserRole.Admin],
  },
  {
    path: "*",
    name: "maintenance",
    component: Maintenance,
    title: "Maintenance",
    useAuthLayout: false,
  },
];

export { routes, maintenanceRoutes };
