export const expired = [
  { label: "Yes", id: "yes" },
  { label: "No", id: "no" },
];

export const OTHER = "Other";

export const listBooleanPGAForm = [
  { label: "Yes", value: true },
  { label: "No", value: false },
];

export const NORTHERN_IRELAND = "Northern Ireland";

export const TABLE_ERROR_TYPE = {
  ALL: "all",
  ROW: "row",
  CELL: "cell",
  NONE: "none",
};

export const valueNoDropdown = listBooleanPGAForm[1];