import { Suspense, useState } from "react";
import { BrowserRouter, Route, Switch } from "react-router-dom";
import Loadable from "react-loadable";
import {
  ApolloClient,
  ApolloProvider,
  InMemoryCache,
  createHttpLink,
  from,
} from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import axios from "axios";
import { routes, maintenanceRoutes } from "./AppRouting";
import withLayout from "helpers/hoc/withLayout";
import { AppContextProvider } from "helpers/contexts/AppContext";
import { AuthService } from "services";
import Loader from "components/Loader";
import { onError } from "@apollo/client/link/error";
import { useIdleTimer } from "react-idle-timer";
import CheckMaintenance from "components/check-maintenance/CheckMaintenance";
import SignalR from "components/signal-r/SignalR";
import { ConfigProvider } from "antd";
import { API } from "helpers";
import { ErrorBoundary, useErrorBoundary } from "react-error-boundary";

const loading = () => <Loader />;

declare global {
  interface Window {
    REACT_APP_GRAPHQL_URL?: null;
    REACT_APP_IDENTITY_URL?: null;
    REACT_APP_CMS_URL?: null;
    REACT_APP_CMS_API_URL?: null;
    REACT_APP_API_GATEWAY_URL?: null;
  }
}

axios.interceptors.request.use(async (config) => {
  const token = await AuthService.getAccessToken();
  config.headers = {
    authorization: token ? `Bearer ${token}` : "",
  };
  return config;
});

const graphQLLink = createHttpLink({
  uri: window.REACT_APP_GRAPHQL_URL + "/graphql",
});

const authLink = setContext(async (_, { headers }) => {
  const token = await AuthService.getAccessToken();
  return {
    headers: {
      ...headers,
      authorization: token ? `Bearer ${token}` : "",
    },
  };
});

const errorLink = onError((error: any) => {
  let data = new FormData();

  data.append(
    "body",
    JSON.stringify(error) +
      " time:" +
      new Date().toLocaleString() +
      " url:" +
      window.location.href +
      " oidc.user:/authority:S5E-CMSSPA:" +
      localStorage?.getItem("oidc.user:/authority:S5E-CMSSPA")
  );

  // data.append("errorLink", JSON.stringify(error));
  // data.append("time", new Date().toLocaleString());
  axios.post(API.saveLogWhenUserLoginErrors, data);

  if (error.networkError?.result?.ResponseCode === 401) {
    let domain = window.location.origin;
    if (error?.operation?.operationName === "getMaintenanceStatus") {
      localStorage.clear();
      window.location.href = window.REACT_APP_CMS_URL;
    } else {
      localStorage.clear();
      window.location.href = domain;
    }
  }
});

const client = new ApolloClient({
  link: from([errorLink, authLink.concat(graphQLLink)]),
  cache: new InMemoryCache(),
});

const AuthLayout = Loadable({
  loader: () => import("components/layout/AuthLayout"),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

const NonAuthLayout = Loadable({
  loader: () => import("components/layout/NonAuthLayout"),
  render(loaded, props) {
    let Component = loaded.default;
    return <Component {...props} />;
  },
  loading,
});

const App = () => {
  const [maintenance, setMaintenance] = useState(false);
  const [loadingPage, setLoadingPage] = useState(true);

  const handleOnIdle = () => {
    AuthService.signOut();
  };

  useIdleTimer({
    timeout: 1000 * 60 * 30,
    onIdle: handleOnIdle,
    debounce: 500,
  });

  const logError = async (error: Error, info: { componentStack: string }) => {
    let data = new FormData();

    data.append(
      "body",
      "error: " +
        JSON.stringify(error) +
        "infoError: " +
        JSON.stringify(info) +
        " time:" +
        new Date().toLocaleString() +
        " url:" +
        window.location.href
    );

    await axios.post(API.saveLogWhenUserLoginErrors, data);
  };

  function ErrorFallback({ error }: { error: Error }) {
    const { resetBoundary } = useErrorBoundary();

    return (
      <div role="alert">
        <p>Something went wrong:</p>
        <pre style={{ color: "red" }}>{error.message}</pre>
        <button onClick={resetBoundary}>Try again</button>
      </div>
    );
  }

  return (
    <>
      <ErrorBoundary FallbackComponent={ErrorFallback} onError={logError}>
        <BrowserRouter>
          <AppContextProvider>
            <ApolloProvider client={client}>
              <ConfigProvider
                theme={{
                  token: {
                    fontFamily: "Ubuntu",
                    colorPrimary: "#0a12bf",
                    fontSize: 16,
                    colorPrimaryHover: "#03002e",
                    colorError: "#ff0000",
                  },
                }}
              >
                <SignalR />
                <CheckMaintenance
                  setMaintenance={setMaintenance}
                  setLoadingPage={setLoadingPage}
                />
                {loadingPage && <></>}
                {!loadingPage && (
                  <>
                    {maintenance ? (
                      <Switch>
                        {maintenanceRoutes.map((route, index) => {
                          return (
                            <Route
                              key={index}
                              path={route.path}
                              exact={route.exact}
                              component={withLayout((props) => {
                                const Layout = route.useAuthLayout
                                  ? AuthLayout
                                  : NonAuthLayout;
                                return (
                                  <Suspense fallback={loading()}>
                                    <Layout
                                      {...props}
                                      title={route.title ? route.title : ""}
                                      permissions={route.permissions ?? []}
                                    >
                                      <route.component {...props} />
                                    </Layout>
                                  </Suspense>
                                );
                              })}
                            />
                          );
                        })}
                      </Switch>
                    ) : (
                      <Switch>
                        {routes.map((route, index) => {
                          return (
                            <Route
                              key={index}
                              path={route.path}
                              exact={route.exact}
                              component={withLayout((props) => {
                                const Layout = route.useAuthLayout
                                  ? AuthLayout
                                  : NonAuthLayout;
                                return (
                                  <Suspense fallback={loading()}>
                                    <Layout
                                      {...props}
                                      title={route.title ? route.title : ""}
                                      permissions={route.permissions ?? []}
                                    >
                                      <route.component {...props} />
                                    </Layout>
                                  </Suspense>
                                );
                              })}
                            />
                          );
                        })}
                      </Switch>
                    )}
                  </>
                )}
              </ConfigProvider>
            </ApolloProvider>
          </AppContextProvider>
        </BrowserRouter>
      </ErrorBoundary>
    </>
  );
};

export default App;
